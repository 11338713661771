<template>
  <div class=container>
<table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2">Gallery of Direct-Global Separation Results</font></div></td>
          </tr>
          <tr>
            <td>Here, we show examples of separating direct and global components of light transport. In each case, shifting high-frequency binary stripes were used to illuminate the scene. <strong>By clicking on each of the thumbnail images shown below, you can see the corresponding high resolution image.</strong><br>
(<router-link :to="{path:'/projects/categories/project',query:{cid:this.$route.query.cid,pid:this.$route.query.pid}}">Back to Main Project Page</router-link>)</td>
          </tr>
          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Grapes</strong></td>
                    <td><div align="right"><strong>Method: Binary Stripes<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/ITotal-ND.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/ITotal-ND.png" width="300" height="202" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene with subsurface scattering</strong></div></td>

                  </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td><div align="center">The grapes are translucent, resulting in subsurface scattering. </div></td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>

                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/Grapes-ND.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/Grapes-ND.gif" width="279" height="199" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Input sequence (conventional SL)</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/Grapes-WD.gif"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/Grapes-WD.gif" width="279" height="199" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Input sequence (diffuse SL)</strong></div></td>
                    </tr>
                </tbody></table>
                
                
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>Linear diffuser preserves the high-frequency nature of the illumination, as can be seen in the input sequence of diffuse SL. Because of this, the direct and global components can be separated. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
              <tr>

              <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IDirect-ND.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IDirect-ND.png" width="279" height="188" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Direct component (conventional SL) </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IDirect-WD.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IDirect-WD.png" width="279" height="188" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Direct component (diffuse SL) </strong></div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IGlobal-ND.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IGlobal-ND.png" width="279" height="188" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Global component (conventional SL) </strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IGlobal-WD.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/grapes/IGlobal-WD.png" width="279" height="188" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Global component (diffuse SL) </strong></div></td>
                    </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>The direct-global separations with conventional and diffuse SL are qualitatively similar. There are some differences. The specularities in the direct component with conventional SL are stronger and concentrated. In comparison, the specularities in the direct component with diffuse SL have a 1D spread. </td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
          </tbody></table></td>
          </tr>






          <tr>
            <td><table width="100%" border="1" cellspacing="0" cellpadding="0">
              <tbody><tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td><strong>Blocks</strong></td>
                    <td><div align="right"><strong>Method: Binary Stripes<br>
                    </strong></div></td>
                  </tr>
                </tbody></table>
                  <div align="right"></div></td>
                </tr>
              <tr>
                <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/ITotal-32-ND.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/ITotal-32-ND.png" width="300" height="194" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Scene with interreflections</strong></div></td>

                  </tr>
                </tbody></table>
</td>
                </tr>

              <tr>

              <td><table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IDirect-32-ND.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IDirect-32-ND.png" width="279" height="180" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Direct component (conventional SL)</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IDirect-32-WD.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IDirect-32-WD.png" width="279" height="180" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Direct component (diffuse SL)</strong></div></td>
                    </tr>
                </tbody></table>
                <table width="100%" border="0" cellspacing="10" cellpadding="0">
                  <tbody><tr>
                    <td width="32%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IGlobal-32-ND.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IGlobal-32-ND.png" width="279" height="180" border="0"></a><br>
                          <span class="style1">.</span><br>
                          <strong>Global component (conventional SL)</strong></div></td>
                    <td width="35%"><div align="center"><a href="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IGlobal-32-WD.png"><img src="https://cave.cs.columbia.edu/old/projects/diffuse_sl/images/blocks/IGlobal-32-WD.png" width="279" height="180" border="0"></a><br>
                      <span class="style1">.</span><br>
                      <strong>Global component (diffuse SL)</strong></div></td>
                    </tr>
                </tbody></table>
                  <table width="100%" border="0" cellspacing="10" cellpadding="0">
                    <tbody><tr>
                      <td>Notice the strong shadows in the direct component without the diffuser. In comparison, the shadows are softer in the direct component with the diffuser.</td>
                    </tr>
                    <tr>
                      <td>&nbsp;</td>
                    </tr>
                  </tbody></table></td>
                </tr>
          </tbody></table></td>
          </tr>








          <tr>
            <td>&nbsp;</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>